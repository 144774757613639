import React from "react"
import { graphql } from "gatsby"

import ServiceLayout from "../../components/Services/ServiceLayout"
import ServiceSection from "../../components/Services/ServiceSection"
import CTA from "../../components/CTA"
import SEO from "../../components/SEO"

const Pilates = ({ data }) => {
  const contentA = (
    <>
      <p>
        Our clinical Pilates services are delivered by a fully certified{" "}
        <a
          href="https://www.stottpilates.com/"
          className="inlineLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          STOTT Pilates
        </a>{" "}
        instructor who works with you to align balance and activate core muscles
        and prevent and rehabilitate injuries. We focus on the spine and joints
        of the body to help create free and efficient movement, with less pain
        and tension.
      </p>
      <p>
        This training method was developed by physiotherapists with a knowledge
        of anatomy and how injuries occur and heal. Pilates Instructors are
        trained to work with people who have had injuries. They also understand
        how to balance the movement around a joint that is healing to remediate
        the injury and prevent it from happening again.
      </p>
      <p>
        If you require clinical Pilates services, we’ll set up an initial
        assessment with our physiotherapist to develop a treatment plan. You
        will work with our clinical Pilates instructor for treatment, and the
        plan will be directed and overseen by our physiotherapist. Coverage for
        clinical Pilates sessions may be available through your extended health
        benefits. Get in touch with our team to see if you’ll qualify.
      </p>
    </>
  )
  return (
    <ServiceLayout serviceTitle="Clinical Pilates">
      <SEO title="Pilates" />
      <ServiceSection
        content={contentA}
        image={data.photoOne}
        subTitle="Align, balance and activate core muscles for optimal movement"
      />

      <CTA
        title="Experience a deeper level of mind-body connection."
        text="Coverage for Pilates is available through Physiotherapy Extended Health Benefits."
        buttonText="Book a session now"
        to="https://artandsciencephysio.janeapp.com/#/staff_member/5"
      />
    </ServiceLayout>
  )
}

export const query = graphql`
  query PilatesQuery {
    photoOne: file(relativePath: { eq: "2022/007A6044.jpg" }) {
      relativePath
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 75, width: 650)
      }
    }
  }
`

export default Pilates
