import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Grid from "../grid/Grid"

// Option B is for a slightly different layout
// Option C is for sections without an image (full width)

const ServiceSection = ({
  subTitle = "",
  content = null,
  className = "",
  optionB = false,
  optionC = false,
  image = null,
}) => {
  return (
    <Grid className={` col-span-full items-start relative ${className}`}>
      <div
        className={` grid gap-y-5 col-span-full md:col-start-2 ${
          optionB ? "md:col-span-5 " : optionC ? "" : "md:col-span-6 "
        }  relative`}
      >
        <h3 className=" md:mt-5 text-2xl md:text-3xl">{subTitle}</h3>
        {content}
      </div>
      <div
        className={`mt-5 md:mt-0 col-span-full ${
          optionB
            ? "md:col-start-8 md:col-end-13 "
            : optionC
            ? "hidden"
            : "md:col-start-9 md:col-end-13 "
        }  sticky top-28`}
      >
        <GatsbyImage image={getImage(image)} imgClassName="rounded-2xl" />
      </div>
    </Grid>
  )
}

export default ServiceSection
