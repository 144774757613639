import { Link } from "gatsby"
import React from "react"

import Grid from "../grid/Grid"
import Layout from "../Layout"

const ServiceLayout = ({
  serviceTitle = "[Error:Missing service title]",
  children,
}) => {
  return (
    <Layout>
      <div>
        <Grid className="  gap-y-10 sm:gap-y-16 md:gap-y-20">
          <div className=" grid  md:col-start-2 col-span-full ">
            <Link
              to="/services"
              className="block mb-3 md:mb-5   justify-self-start"
            >
              <h4 className=" hover:underline hover:text-storm-blue ">
                Services
              </h4>
            </Link>
            <h1>{serviceTitle}</h1>
          </div>
          {children}
        </Grid>
      </div>
    </Layout>
  )
}

export default ServiceLayout
